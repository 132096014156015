import { useState, useEffect, useRef, useCallback } from "react";
import { motion } from "framer-motion";

import Modal from '../modal/Modal';

import style from './Jumbotron.module.css';

const Jumbotron = (props) => {

    const {
        title,
        image,
        instance
    } = props.content;

    const [hover, setHover] = useState(false);
    const [imgLoaded, setimgLoaded] = useState(false);

    const ref = useRef();

    useEffect(() => {
        if (image) {
            const img = new Image();
            const handleImageLoad = () => {
                ref.current.style.backgroundImage = `url('${image.fields.file.url}')`;
                setimgLoaded(true);
            };
            img.addEventListener('load', handleImageLoad);
            img.src = image.fields.file.url;

            return () => {
                img.removeEventListener('load', handleImageLoad);
            };
        };
    }, [image]);

    return (
        <motion.div id={style['jumbotron']} animate={{ opacity: imgLoaded ? 1 : 0}}>
            <motion.h1
                animate={{ scale: hover ? 1.01 : 1 }}
                transition={{ duration: 0.7 }}
            >{title}</motion.h1>
            <div
                id={style['hero-img']}
            >
                <motion.div
                    ref={ref}
                    style={{
                        backgroundPosition: 'bottom',
                        backgroundSize: 'cover'
                    }}
                    onMouseOver={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    animate={{ scale: hover ? 1.01 : 1 }}
                    transition={{ duration: 1.2 }}
                ></motion.div>
            </div>
            {instance === 'home' &&
                <Modal />
            }
        </motion.div>
    );

};

export default Jumbotron;