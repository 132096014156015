import { useState } from "react";
import { motion } from "framer-motion";

import style from './PlatformCard.module.css';

const PlatformCard = (props) => {

    const {
        name,
        stats,
        stat1,
        stat2,
        stat3,
        icon,
        text
    } = props.content;

    const [hover, setHover] = useState(false);

    return (
        <motion.div
            className={style['card']}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            animate={{ scale: hover ? 1.01 : 1 }}
            transition={{ duration: 1.2 }}
        >
            <div className={style['flag']}>
                <div style={{ backgroundImage: `url('${icon.fields.file.url}')` }}></div>
            </div>
            <div className={style['card-details']}>
                <h4>{name}</h4>
                <p>{stat1}</p>
                <p>{stat2}</p>
                <p>{stat3}</p>
                <p>{text}</p>
            </div>
        </motion.div>
    );

};

export default PlatformCard;