import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { getContent } from '../../Utils';

import style from './Map.module.css';

const Map = (props) => {

    const {
        content
    } = props;

    const [data, setData] = useState(null);

    const mapContainer = useRef();

    const fetch = async (url) => {
        axios.get(url)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error('Error fetching JSON from S3', error);
            });
    };

    useEffect(() => {
        fetch(content.url);
    }, []);

    useEffect(() => {

        if (!data) return;

        mapboxgl.accessToken = 'pk.eyJ1IjoiemVyb2Ryb29sIiwiYSI6ImNsb29jNHBnczAwNjEyam83dWgyaWlpb3UifQ.2jxiv0aq-mMlsDC-moxRVA';
        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/zerodrool/cloreiekb00ne01nzba9nbfhm',
            center: [process.env.REACT_APP_MAP_CENTER_X, process.env.REACT_APP_MAP_CENTER_Y],
            zoom: process.env.REACT_APP_MAP_ZOOM,
            minZoom: process.env.REACT_APP_MAP_ZOOM,
            maxZoom: process.env.REACT_APP_MAP_ZOOM,
            attributionControl: false,
            scrollZoom: false,
            dragRotate: false
        });

        const navControl = new mapboxgl.NavigationControl({
            showCompass: false
        });
        map.addControl(navControl);

        map.on('load', () => {

            map.addSource('places', {
                type: 'geojson',
                data: data
            });

            map.addLayer({
                id: 'places',
                type: 'circle',
                source: 'places',
                paint: {
                    'circle-radius': 8,
                    'circle-color': [
                        'match',
                        ['get', 'Technology'],
                        'Wind', 'rgba(0, 107, 184, 0.42)',
                        'PV', 'rgba(241, 154, 56, 0.6)',
                        'BESS', '#737B8F',
                        'Green H2', 'rgba(1, 120, 1, 0.6)',
                        '#808080'
                    ]
                },
            });

            const WORLD_VIEW = "MA"
            var adminLayers = ['admin-0-boundary', 'admin-1-boundary', 'admin-0-boundary-disputed', 'admin-1-boundary-bg', 'admin-0-boundary-bg', 'country-label'];
            adminLayers.forEach((adminLayer) => {
                map.setFilter(adminLayer, ["match", ["get", "worldview"], ["all", WORLD_VIEW], true, false]);
            });

            // const popup = new mapboxgl.Popup({
            //     closeButton: false,
            //     closeOnClick: false,
            // });

            // map.on('mouseenter', 'places', (e) => {
            //     const { lng, lat } = e.lngLat;
            //     const { Title, Technology, Capacity } = e.features[0].properties;

            //     popup.setLngLat([lng, lat])
            //         .setHTML(`<div class="popup"><h4>${Title}</h4><p>Technology: ${Technology}</p><p>Capacity: ${Capacity}MW</p></div>`)
            //         .addTo(map);
            // });

            // map.on('mouseleave', 'places', () => {
            //     popup.remove();
            // });

            if (process.env.REACT_APP_MAP_MAX_BOUNDS_X !== 'null') {
                map.setMaxBounds([[process.env.REACT_APP_MAP_MIN_BOUNDS_X, process.env.REACT_APP_MAP_MIN_BOUNDS_Y], [process.env.REACT_APP_MAP_MAX_BOUNDS_X, process.env.REACT_APP_MAP_MAX_BOUNDS_Y]]);
            };

            map.scrollZoom.disable();

        });

        return () => map.remove();

    }, [data]);

    return (
        <div>
            <h2>{content.title}</h2>
            <div ref={mapContainer} className={style['map']}></div>
        </div>
    )

};

export default Map;