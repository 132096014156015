import { useState } from "react";
import { motion } from "framer-motion";

import style from './Project.module.css';

const Project = (props) => {

    const { i } = props;

    const {
        Title,
        Technology,
        Capacity,
        Country,
        tech
    } = props.content;

    const [hover, setHover] = useState(false);

    return (
        <motion.div
            className={style['project']}
            onMouseOver={() => setHover(true)} // Event handler for mouse over event
            onMouseLeave={() => setHover(false)} // Event handler for mouse leave event
            animate={{ scale: hover ? 1.01 : 1 }} // Animation for scaling the component on hover
            transition={{ duration: 1.2 }} // Transition duration for the animation
        >
            {/* Mapping over the 'tech' array and rendering a div for each item */}
            {tech.map((item, i) => {
                return (
                    <div key={`tech_${i}`} className={style[`${item.replace(/\s/g, '').toLowerCase()}`]}>
                        <motion.span
                            className="material-symbols-outlined"
                            animate={{ scale: hover ? 1.6 : 1 }} // Animation for scaling the span on hover
                            transition={{ duration: 3.6 }} // Transition duration for the animation
                        >{item === 'PV' ? 'wb_sunny' : item === 'Wind' ? 'wind_power' : item === 'Green H2' ? 'water' : 'battery_charging_full'}</motion.span>
                    </div>
                )
            })}
            <div className={style['project-details']}>
                <div>
                    <h4>{Title}</h4>
                    {/* Conditional rendering based on the value of the REACT_APP_PLATFORM environment variable */}
                    {process.env.REACT_APP_PLATFORM === 'OCM' &&
                        <p>Technology: {Technology}</p>
                    }
                    {/* {process.env.REACT_APP_PLATFORM !== 'OCM' &&
                        <p>Country: {Country}</p>
                    } */}
                    <p>Capacity: {Capacity > 0 ? Capacity + 'MW' : '-'}</p>
                </div>
            </div>
        </motion.div>
    );

};

// Exporting the Project component as the default export
export default Project;