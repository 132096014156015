import { useState } from "react";
import { motion } from "framer-motion";

import style from './TechnologyCard.module.css';

const TechnologyCard = (props) => {

    const {
        name,
        numberOfProjects,
        numberOfRegions,
        capacity,
        icon,
        text
    } = props.content;

    const [hover, setHover] = useState(false);

    return (
        <motion.div
            className={style['card']}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            animate={{ scale: hover ? 1.01 : 1 }}
            transition={{ duration: 1.2 }}
        >
            <div className={style[`${name.toLowerCase()}`]}>
                <motion.span
                    className="material-symbols-outlined"
                    animate={{ scale: hover ? 1.6 : 1 }}
                    transition={{ duration: 3.6 }}
                >{icon}</motion.span>
            </div>
            <div className={style['card-details']}>
                <h4>{name}</h4>
                <p>{numberOfProjects} Projects</p>
                <p>{numberOfRegions} Regions</p>
                <p>{capacity} MW</p>
                <p>{text}</p>
            </div>
        </motion.div>
    );

};

export default TechnologyCard;