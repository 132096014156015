import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from 'axios';

import Project from "../project/Project";

import style from './Projects.module.css';

// Filter component for selecting project filters
const Filter = (props) => {

    const {
        filters,
        selected,
        setSelected
    } = props;

    return (
        <div id={style['project-filter']}>
            {filters.map((item, i) => {
                return (
                    <div key={`filter_${i}`} className={selected === item ? style['selected'] : null} onClick={() => setSelected(item)}>{item}</div>
                )
            })}
        </div>
    );

};

// Projects component for displaying and filtering projects
const Projects = (props) => {

    const {
        content,
        instance
    } = props;

    const [data, setData] = useState(null);
    const [filters, setFilters] = useState(null);
    const [list, setList] = useState(true);
    const [selected, setSelected] = useState(null);

    // Fetch data from the provided URL
    const fetch = async (url) => {
        axios.get(url)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error('Error fetching JSON from S3', error);
            });
    };

    useEffect(() => {
        // Fetch data when the component mounts
        fetch(content.url);
    }, []);

    useEffect(() => {
        if (data) {

            // Split the Technology property into an array of values
            if (process.env.REACT_APP_PLATFORM === 'OCM') {
                data.features.sort((a, b) => {
                    const sortA = a.properties.Technology.toLowerCase();
                    const sortB = b.properties.Technology.toLowerCase();
                    if (sortA < sortB) {
                        return -1;
                    }
                    if (sortA > sortB) {
                        return 1;
                    }
                    return 0;
                });
            };    

            data.features.forEach(item => {
                item.properties.tech = item.properties.Technology.split(', ');
            });

            // Determine the parameter based on the platform
            const filterParam = process.env.REACT_APP_PLATFORM === 'OCM' ? 'Country' : 'tech';

            // Generate the filters array based on the parameter
            const arr = data.features.reduce((filters, item) => {
                if (filterParam === 'Country') {
                    // Add unique Country values to the array
                    if (!filters.includes(item.properties.Country)) {
                        filters.push(item.properties.Country);
                    }
                } else if (filterParam === 'tech') {
                    // Add unique tech values to the array
                    item.properties.tech.forEach(tech => {
                        if (!filters.includes(tech)) {
                            filters.push(tech);
                        }
                    });
                }
                return filters;
            }, []);

            // Set the filters state with the generated array
            setFilters(arr);
        }
    }, [data]);

    useEffect(() => {
        // Set the selected filter when the filters state changes
        if (filters) setSelected(filters[0]);
    }, [filters]);

    return (
        <div id={style['projects']}>
            {filters &&
                <Filter filters={filters} selected={selected} setSelected={setSelected} />
            }
            {list && selected && data?.features?.map((item, i) => {
                let param = process.env.REACT_APP_PLATFORM === 'OCM' ? 'Country' : 'Technology';
                if (item.properties[param] === selected) return (
                    <div key={`project_${i}`}>
                        <Project content={item.properties} i={i} />
                    </div>
                )
            })}
        </div>
    );

};

export default Projects;