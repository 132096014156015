import { useState, useEffect } from "react";
import { getContent } from '../../Utils';

import TechnologyCard from '../technology-card/TechnologyCard';
import NewsCard from "../news-card/NewsCard";
import CareerCard from "../career-card/CareerCard";
import Project from "../project/Project";
import TeamMember from "../team-member/TeamMember";
import PlatformCard from "../platform-card/PlatformCard";
import { Heading, Paragraph, Image } from '../elements/Elements';

import style from './Group.module.css';

const elements = {
    technologyCard: TechnologyCard,
    newsItem: NewsCard,
    careerOpportunity: CareerCard,
    project: Project,
    image: Image,
    teamMember: TeamMember,
    platformCard: PlatformCard,
    paragraph: Paragraph
};

const Group = (props) => {

    const {
        id
    } = props;

    const [content, setContent] = useState(null);
    const [largeLayout, setLargeLayout] = useState(window.innerWidth > 1100 ? true : false);

    const fetch = async (id) => {
        let c = await getContent('single', id, 'en-US');
        setContent(c);
    };

    const handleResize = (e) => {
        if (e.target.innerWidth > 1100) setLargeLayout(true)
        else setLargeLayout(false);
    };

    useEffect(() => {
        fetch(id);
    }, [id]);

    useEffect(() => {
        window.addEventListener('resize', (e) => handleResize(e));
    }, []);

    return (
        <>
            {content?.items &&
                <div className={style['group']} style={{
                    display: !content.horizontal ? 'block' : 'grid',
                    height: !content.horizontal ? 'max-content' : null,
                    margin: !content.horizontal ? '0px' : '40px 0px',
                    // gridTemplateColumns: largeLayout ? `repeat(${content.items.length < 4 ? content.items.length : 4}, auto)` : null,
                }}>
                    {content?.items.map((item, i) => {
                        const Component = item?.sys?.contentType?.sys?.id === 'group' ? Group : elements[item.sys.contentType.sys.id];
                        return <Component key={`element_${i}_${item.sys.contentType.sys.id}`} id={item.sys.id} content={item.fields} />;
                    })}
                </div>
            }
        </>
    )

};

export default Group;