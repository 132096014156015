import {
    createClient
} from 'contentful';

const getContent = (request, type, language) => {
    return new Promise(resolve => {

        const client = createClient({
            space: process.env.REACT_APP_SPACE_ID,
            accessToken: process.env.REACT_APP_ACCESS_TOKEN
        });

        if (request === 'multi') {

            client.getEntries({
                content_type: type,
                locale: language
            })
                .then((response) => {

                    resolve(response.items);

                })
                .catch(console.error);

        } else {

            client.getEntry(type, {
                locale: language
            })
                .then((response) => {

                    resolve(response.fields);

                })
                .catch(console.error);

        }


    })
};

export { getContent };