import { useState, useEffect } from 'react';
import { getContent } from '../../Utils';

import Jumbotron from '../jumbotron/Jumbotron';
import SectionHeader from '../section-header/SectionHeader';
import { Heading, Paragraph, Image } from '../elements/Elements';
import Group from '../group/Group';
import ContactForm from '../contact-form/ContactForm';
import Map from '../map/Map';
import Projects from '../projects/Projects';

import style from './Page.module.css';

const elements = {
    jumbotron: Jumbotron,
    sectionHeader: SectionHeader,
    heading: Heading,
    paragraph: Paragraph,
    group: Group,
    image: Image,
    map: Map,
    projects: Projects
};

const Page = (props) => {

    const {
        instance,
        name,
        id,
        index
    } = props;

    const [content, setContent] = useState(null);

    const fetch = async (id) => {
        let c = await getContent('single', id, 'en-US');
        setContent(c);
    };

    useEffect(() => {
        fetch(id);
    }, [id]);

    return (
        <div className={style['page']}>
            <div className={style['content']}>
                {content?.content?.map((item, i) => {
                    let limit = index === 0 ? content.limit : content.limit + 1;
                    if (((instance === 'home' && (i < limit || !limit)) || instance === 'page')) {
                        if (instance === 'home' && item.sys.contentType.sys.id === 'jumbotron' && index !== 0) return null
                        else {
                            const Component = elements[item.sys.contentType.sys.id];
                            return <Component key={`element_${i}_${item.sys.contentType.sys.id}`} id={item.sys.id} content={item.fields} instance={instance} />;
                        }
                    }
                })}
                {name === 'Contact' &&
                    <ContactForm />
                }
            </div>
        </div>
    )

};

export default Page;