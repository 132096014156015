import { useState, useEffect, createContext } from "react";
import { Routes, Route, Outlet } from "react-router-dom";

import { getContent } from './Utils';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Page from "./components/page/Page";
import PopUp from "./components/pop-up/PopUp";
import { Blur } from './components/elements/Elements'

import style from './App.module.css';

export let LanguageContext = createContext({});
export let OrientationContext = createContext({});

const blurs = [
    {
        color: '#ff8908',
        opacity: 0.5,
        size: {
            width: '200px',
            height: '180px'
        },
        position: {
            x: '95%',
            y: '80%'
        },
        duration: 10,
        delay: 0
    },
    {
        color: '#006bb8',
        opacity: 0.2,
        size: {
            width: '200px',
            height: '180px'
        },
        position: {
            x: '-5%',
            y: '40%'
        },
        duration: 20,
        delay: 5
    },
    {
        color: '#ff8908',
        opacity: 0.8,
        size: {
            width: '70px',
            height: '70px'
        },
        position: {
            x: '65%',
            y: '25%'
        },
        duration: 15,
        delay: 3
    }
];

const Layout = (props) => {

    const {
        content,
        cookiesAccepted
    } = props;

    return (
        <div>
            <Header content={content} />
            <Outlet />
            <Footer content={content} />
            {blurs?.map((item, i) => {
                return <Blur key={`blur_${i}`} style={item} />
            })}
            {cookiesAccepted === null &&
                <PopUp {...props} />
            }
        </div>
    )

}

const App = () => {

    const [orientation, setOrientation] = useState(window.matchMedia("(orientation: portrait)").matches ? 'portrait' : 'landscape');
    const [language, setLanguage] = useState('en-US');
    const [content, setContent] = useState(null);
    const [cookiesAccepted, setCookiesAccepted] = useState(null);

    const fetch = async () => {

        let c = await getContent('multi', 'app', 'en-US');
        let arr = [];
        for (const elem of c[0].fields.pages) {
            arr.push(elem);
        };
        setContent(arr);

    };

    useEffect(() => {

        window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
            const portrait = e.matches;

            if (portrait) setOrientation('portrait')
            else setOrientation('landscape')
        });

        fetch();

        console.log('website developed by Ortus Technology');
    }, []);

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            <OrientationContext.Provider value={{ orientation, setOrientation }}>
                <div id={style['app']}>
                    <Routes>
                        <Route path="/" element={<Layout cookiesAccepted={cookiesAccepted} setCookiesAccepted={setCookiesAccepted} content={content} />}>
                            <Route index element={
                                <div>
                                    {content?.map((item, i) => {
                                        if (item.fields.showOnHomepage) return (
                                            <Page key={`home_item_${i}`} instance={'home'} name={item.fields.name} id={item.sys.id} index={i} />
                                        )
                                    })}
                                </div>
                            } />
                            {content?.map((item, i) => {
                                return (
                                    <Route key={`route_${i}`} path={item.fields.url} element={<Page instance={'page'} name={item.fields.name} id={item.sys.id} />} />
                                )
                            })}
                        </Route>
                    </Routes>
                </div>
            </OrientationContext.Provider>
        </LanguageContext.Provider>
    );

};

export default App;
