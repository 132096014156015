import style from './NewsCard.module.css';

const NewsCard = (props) => {

    const {
        date,
        title,
        pdf
    } = props.content;

    return (
        <div className={style['news-card']}>
            <p>{date}</p>
            <h4>{title}</h4>
            <div>
                <a href={pdf.fields.file.url} target='_blank'>Full Article</a>
                <span className="material-symbols-outlined">trending_flat</span>
            </div>
        </div>
    )

};

export default NewsCard;