import style from './Modal.module.css';

const Modal = () => {

    return (
        <div id={style['modal']}>
            <div>
                <h3>150MW</h3>
                <h4>Worldwide portfolio</h4>
            </div>
            <img src="/assets/american-public-power-association-XGAZzyLzn18-unsplash.jpeg" />
        </div>
    );

};

export default Modal;