import { motion } from "framer-motion";

import style from './PopUp.module.css';

const PopUp = (props) => {

    const {
        cookiesAccepted,
        setCookiesAccepted
    } = props;

    const handleClick = (accept) => {
        setCookiesAccepted(accept ? true : false);
    };

    return (
        <motion.div id={style['pop-up']} initial={{ y: '100%' }} animate={{ y: 0 }} transition={{ duration: 0.6 }}>
            <p>This website uses cookies to enhance your browsing experience. By clicking 'Accept' you consent to our use of cookies.</p>
            <div>
                <motion.div whileHover={{ scale: 1.1 }} whileTap={{scale: 0.95}} onClick={() => handleClick(true)}>Accept</motion.div>
                <motion.div whileHover={{ scale: 1.1 }} whileTap={{scale: 0.95}} onClick={() => handleClick(false)}>Reject</motion.div>
            </div>
        </motion.div>
    )

};

export default PopUp;