import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import style from './Logo.module.css';

const Logo = () => {

    return (
        <Link id={style['logo']} to="/">
            <motion.img
                src={process.env.REACT_APP_LOGO}
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.99 }}
            />
        </Link>
    );

};

export default Logo;