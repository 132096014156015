import { useState, useEffect, useRef } from 'react';
import { marked } from 'marked';
import { motion } from "framer-motion";

import style from './Elements.module.css';

const Heading = (props) => {

    const {
        text
    } = props.content;

    return (
        <h3>{text}</h3>
    )

};

const Paragraph = (props) => {

    const {
        text
    } = props.content;

    const ref = useRef();

    useEffect(() => {
        if (ref?.current) ref.current.innerHTML = marked.parse(text);
    }, [text]);

    return (
        <p ref={ref} className={style['paragraph']}></p>
    )

};

const Image = (props) => {

    const {
        alt,
        file
    } = props.content;

    const [loaded, setLoaded] = useState(false);

    return (
        <motion.img onLoad={() => setLoaded(true)} animate={{ opacity: loaded ? 1 : 0 }} src={file.fields.file.url} alt={alt} />
    )

};

const Blur = (props) => {

    const {
        color,
        size,
        position,
        opacity,
        duration
    } = props.style;

    return (
        <motion.div
            className={style['blur']}
            style={{
                top: position.y,
                left: position.x,
                width: size.width,
                height: size.height,
                // opacity: opacity,
                backgroundColor: color
            }}
            animate={{ scale: [1, 1.8, 1, 1.5, 1], opacity: [0, opacity, 0] }}
            transition={{ duration: duration, repeat: Infinity }}
        ></motion.div>
    )

};

export {
    Heading,
    Paragraph,
    Image,
    Blur
};