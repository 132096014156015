import { useState, useEffect, useRef } from 'react';
import { post } from 'aws-amplify/api';

import Button from "../button/Button";

import style from './ContactFrom.module.css';

const ContactForm = (props) => {

    const [errorMsg, setErrorMsg] = useState(null);

    const name = useRef();
    const email = useRef();
    const message = useRef();

    const handleSubmit = async () => {
        console.log('submit');

        if (!name.current.value || !email.current.value || !message.current.value) {
            setErrorMsg('Please complete the form');
            return;
        };

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.current.value)) {
            setErrorMsg('Invalid email address');
            return;
        };

        try {

            const restOp = post({
                apiName: 'ortusAPI',
                path: '/submit',
                options: {
                    body: {
                        name: name?.current?.value,
                        email: email?.current?.value,
                        message: message?.current?.value,
                        platform: process.env.REACT_APP_PLATFORM
                    }
                }
            });

            const resp = await restOp.response;

            console.log(resp);
            name.current.value = '';
            email.current.value = '';
            message.current.value = '';
            setErrorMsg(null);

        } catch (err) {
            console.log(err);
            name.current.value = '';
            email.current.value = '';
            message.current.value = '';
            setErrorMsg(null);
        };

    };

    return (
        <div id={style['contact-form']}>
            <form>
                <h2>Get in touch</h2>
                <div>
                    <input ref={name} name='name' type='text' placeholder='Your name' />
                    <input ref={email} name='email' type='text' placeholder='Your email address' />
                </div>
                <textarea ref={message} name='message' placeholder='Enquiry' />
                <div
                    onClick={() => handleSubmit()}
                >
                    <Button label={'Send Enquiry'} url={null} />
                </div>
                <p id={style['error-message']}>{errorMsg}</p>
            </form>
        </div>
    )

};

export default ContactForm;