import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import style from './Button.module.css';

const Button = (props) => {

    const {
        label,
        url
    } = props;

    return (
        <motion.div
            whileHover={{scale: 1.02}}
            whileTap={{scale: 0.95}}
        >
            <Link to={url} className={style['button']}>
                <p>{label}</p>
                <span className="material-symbols-outlined">trending_flat</span>
            </Link>
        </motion.div>
    );

};

export default Button;