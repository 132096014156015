import style from './CareerCard.module.css';

const CareerCard = (props) => {

    const {
        title,
        link
    } = props.content;

    return (
        <div className={style['career-card']}>
            <h4>{title}</h4>
            {link &&
                <div>
                    <a href={link} target='_blank'>More Info</a>
                    <span className="material-symbols-outlined">trending_flat</span>
                </div>
            }
        </div>
    )

};

export default CareerCard;