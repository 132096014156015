import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import Logo from '../logo/Logo';
import LanguageSwitch from "../language-switch/LanguageSwitch";

import style from './Header.module.css';

const Header = (props) => {

    const {
        content
    } = props;

    const [collapsed, setCollapsed] = useState(window.innerWidth > 1100 ? false : true);
    const [menu, setMenu] = useState(false);

    const handleResize = (e) => {
        let c = e.target.innerWidth > 1100 ? false : true;
        if (collapsed !== c) setCollapsed(c);
    };
    
    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, [collapsed]);

    return (
        <div id={style['header']}>
            <Logo />
            {!collapsed &&
                <nav id={style['landscape-nav']}>
                    {content?.map((item, i) => {
                        if (item.fields.showInHeader) return (
                            <Link key={`landscape_nav_${i}`} to={item.fields.url}>
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >{item.fields.name}</motion.div>
                            </Link>
                        )
                    })}
                    {/* <LanguageSwitch /> */}
                </nav>
            }
            {collapsed &&
                <div>
                    <span className="material-symbols-outlined" onClick={() => setMenu(!menu)}>{menu ? 'close' : 'menu'}</span>
                    {/* <LanguageSwitch /> */}
                </div>
            }
            {menu && collapsed &&
                <div id={style['menu']}>
                    <nav id={style['portrait-nav']} onClick={() => setMenu(false)}>
                        {content?.map((item, i) => {
                            if (item.fields.showInHeader) return (
                                <Link key={`portrait_nav_${i}`} to={item.fields.url}>
                                    <motion.div
                                        initial={{ x: -10, opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        transition={{ delay: 0.1 * i }}
                                    >{item.fields.name}</motion.div>
                                </Link>
                            )
                        })}
                    </nav>
                </div>
            }
        </div>
    );

};

export default Header;