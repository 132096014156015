import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import style from './TeamMember.module.css';

const TeamMember  = (props) => {

    const {
        name,
        position,
        bio,
        image
    } = props.content;

    const [imgLoaded, setImgLoaded] = useState(false);

    return (
        <motion.div className={style['team-member']} animate={{ opacity: imgLoaded ? 1 : 0}}>
            <img onLoad={() => setImgLoaded(true)} src={image.fields.file.url} />
            <h4>{name}</h4>
            <p>{position}</p>
            <p>{bio}</p>
        </motion.div>
    )

};

export default TeamMember;