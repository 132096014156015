import { Link } from "react-router-dom";

import Logo from '../logo/Logo';

import style from './Footer.module.css';

const Footer = (props) => {

    const {
        content
    } = props;

    return (
        <div id={style['footer']}>
            <div id={style['upper-section']}>
                <Logo />
                <div id={style['links']}>
                    {content?.map((item, i) => {
                        if (item.fields.showInUpperFooter) return (
                            <Link key={`footer_link_${i}`} to={item.fields.url}>{item.fields.name}</Link>
                        )
                    })}
                </div>
            </div>
            <hr />
            <div id={style['lower-section']}>
                <p>Copyright 2023</p>
                <div>
                    {content?.map((item, i) => {
                        if (item.fields.showInLowerFooter) return (
                            <Link key={`footer_link_${i}`} to={item.fields.url}>{item.fields.name}</Link>
                        )
                    })}
                    {/* <p>Terms</p>
                    <p>Privacy</p>
                    <p>Cookie Policy</p> */}
                </div>
            </div>
        </div>
    );

};

export default Footer;