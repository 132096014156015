import Button from '../button/Button';

import style from './SectionHeader.module.css';

const SectionHeader = (props) => {

    const {
        instance
    } = props;

    const {
        title,
        tagline,
        button
    } = props.content;

    return (
        <div className={style['section-header']} style={{ marginTop: instance === 'page' ? '84px' : '0px'}}>
            <div>
                <h4>{title}</h4>
                <h2>{tagline}</h2>
            </div>
            {instance === 'home' &&
                <Button label={button} url={`/${title.toLowerCase()}`} />
            }
        </div>
    );

};

export default SectionHeader;